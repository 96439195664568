.akademi {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-flow: wrap;
   
  
  &__list {
    margin-right: $gutter-small;
    margin-top: 20px;
    margin-bottom: 20px;
  
    img {
      width: 120px;
      height: 110px;
      }
  
    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border: 1px solid $color-black;
      // padding: $gutter-small;
      padding: 0 10px 0 10px;
      border-radius: 10px;
      height: 190px;
      width: 210px;
      }
    }
  }