.laporan-ki4{

  &__header-content {
    position: absolute;
  }

   &__content {
     margin-top: 2rem;
   }
   &__field {
       margin-top: -4rem;
       margin-left: 70rem;
       font-weight: bold;
   }
 
   &__custom-form-row {
     display: flex;
     flex-direction: row;
     margin-left:8rem;
     margin-top: 5rem;
     &__field {
       display: flex;
       flex-direction: row;
       flex: 1;
     }
   }

   &__custom-form-column {
       display: flex;
       flex-direction: column;
   
       &__field {
         display: flex;
         margin-top:8rem;
         flex-direction: column;
         flex: 1;
       }
     }

     &__custom-form-column2 {
       display: flex;
       flex-direction: column;
       margin-left: 10rem;
       margin-top:-4rem;
       &__field {
         display: flex;
         margin-top:7rem;
         flex-direction: column;
         flex: 1;
       }
     }
     &__custom-form-column3 {
       display: flex;
       flex-direction: column;
       margin-left: 40rem;
   
       &__field {
         display: flex;
         margin-top:10rem;
         flex-direction: column;
         flex: 1;
       }
       &__field2 {
           display: flex;
           margin-top:8rem;
           flex-direction: column;
           flex: 1;
         }
     }

   &__custom-form-row2 {
     display: flex;
     flex-direction: row;
 
     &__field {
       display: flex;
       flex-direction: row;
       flex: 1;
 
       &:not(:nth-child(1)) {
         margin-left: 4rem;
       }
     }
   }

   

   &__custom-form-row-square {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       &__field {
         display: flex;
         flex-direction: row;
         flex: 1;
         margin-left: 1rem;
        
       }
     }

     &__custom-form-row-square2 {
       display: flex;
       flex-direction: row;
       margin-top:2rem;
       justify-content: space-between;
       margin-left: 10rem;
       &__field {
         display: flex;
         flex-direction: row;
         flex: 1;
         margin-left: 1rem;
        
       }
     }

   &__

   &__header-table {
     display: flex;
     flex-direction: row;
     margin-top: 5rem;
     
   }

   &__header-table2 {
     margin-top: 5rem;
     
   }

   &__header {
     padding: $gutter-small;
     display: flex;
     flex-direction: column;
     justify-content: center;
     
   }
   &__header-top {
     display: flex;
     flex-direction: row;
     margin-left: 60rem;
     font-size: 14px;
     font-weight: bold;

     &--left {
       font-size: $font-size-default;
       font-weight: bold;
     }
 
     &--right {
       font-size: $font-size-default;
       font-weight: bold;
     }
   }
 
   &__header-title {
     margin-top: $gutter-small;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     font-weight: bold;
     font-size: 24px;
     

     &--column {
       flex-direction: column;
       text-align: center;
       margin-right: 15rem;
     }
   }

   &__header-title-input {
     margin-top: $gutter-small;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     font-weight: bold;
     font-size: 24px;
     

     &--column {
       flex-direction: column;
       text-align: center;
       margin-right: 65rem;
     }
   }

   &__footer-title {
     margin-top: $gutter-small;
     display: flex;
     flex-direction: column;
     font-weight: bold;
     font-size: 14px;
     &--column {
       flex-direction: column;
       margin-right: 25rem;
     }

     &--column2 {
       flex-direction: column;
       margin-right: 15rem;
       margin-top:5rem;
     }
   }

   &__column-field {
       flex-direction: column;
       text-align: center;
       
     }

     &__box {
       display: flex;
       // flex-direction: row;
       justify-content: space-between;
       &--square2{
           font-size: $font-size-default;
           flex-direction: column;
           width: 120px;
           height:120px;
           border: 1px solid black;
           margin: 5px;
           margin-left: 4rem;
          
         }
       
     }


     &__box2 {
       display: flex;
       // flex-direction: row;
       justify-content: space-between;
       
       &--square2{
           font-size: $font-size-default;
           flex-direction: column;
           width: 120px;
           height:120px;
           border: 1px solid black;
           margin: 5px;
           margin-left: 2rem;
          
         }
       
     }

     &__header-title-table {
       font-size: 16px;
       display: flex;
       font-weight: bold;
       margin-left:2rem;
       margin-top: 2rem;
       &--column {
         flex-direction: column;
         
       }
   }
       
   &__custom-form-row-title {
       display: flex;
       flex-direction: row;
       &__field {
         display: flex;
         flex-direction: row;
       }
     }
   &__header-title2 {
     font-size: 16px;
     display: flex;
     margin-left:2rem;
     &--row {
       display: flex;
       flex-direction: row;
     }
     &--column {
       flex-direction: column;
     }
     &--bold {
         font-weight:bold;
     }
   }
   &__header-title3 {
       display: flex;
       flex-direction: row;
       justify-content: space-between;
       width: 100%;
       
       // align-items: flex-start;
       // margin-top: 10rem;
   
       &--left {
         
         font-size: $font-size-default;
         flex-direction: column;
         width:100%;
         margin-left:2rem;
         
       }
   
       &--right {
         font-size: $font-size-default;
         flex-direction: column;
         width:100%;
       }
     }


 
   &__header-info {
 
   }
 
   &__body {
     margin-top: $gutter-extra-small;
     margin-left: 2rem;
   }
 
   &__table-test{
       width: 80%;
       td {
           font-size: 16px;
           padding-top: 1px;
         }
   }
   &__table {
       border-collapse: collapse;
       width: 90%;
       margin-bottom: $gutter-default;
       
   
       td, th {
         border: 1px solid $color-black;
         padding: 8px;
        
         color: $color-black;
       }
   
       tr td, tr th {
         &:nth-child(1) {
           width: 1%;
         }
       }
   
       td.nominal {
         text-align: right;
       }
       th.kelompok {
           text-align: left;
       }
   
       td {
         font-size: $font-size-default;
         
       }
   
       th {
         font-size: $font-size-default;
         padding-top: 12px;
         padding-bottom: 12px;
         background-color: white;
         text-align: center;
       }
     }

   &__table2 {
      
       margin-right: 10rem;
       margin-top:2rem;
       width: 60%;
       
   
       td, th {
         color: $color-black;
         font-size: 14px;
       }
   
       tr td, tr th {
         &:nth-child(1) {
           width: 1%;
         }
       }
   
       td.batas {
         font-size :16px ;
         padding-top: 1px;
         margin-left: 10rem;
       }
   
       td {
         font-size: 16px;
         padding-top: 1px;
       }
   
       th {
         font-size: 15px;
         padding-top: 12px;
         padding-bottom: 12px;
         text-align: left;
       }
     }
 
   tfoot {
     td {
       font-weight:bold;
 
       &:last-child {
         border-left: 5px double #000000;
       }
     }
 
     tr:first-child td {
       border-top:5px double #000000;
     }
   }
 
   thead, tbody, tfoot {
   //   th:last-child, td:last-child {
   //     border-left: 5px double #000000;
   //   }
   }
 
   &__footer {
     padding: $gutter-extra-small 0 0 0;
     border: 1px solid $color-black;
   }
 
   &__footer-top {
     display: flex;
     flex-direction: row;
     justify-content: space-between;
 
     &--left {
       padding: 0 $gutter-extra-small;
       flex: 3;
       display: flex;
       flex-direction: column;
       font-size: $font-size-default;
       font-weight: bold;
     }
 
     &--right {
       flex: 2;
       display: flex;
       flex-direction: column;
       justify-content: flex-start;
 
       p {
         font-size: $font-size-default;
         font-weight: bold;
       }
     }
 
     &--total {
       padding: 0 $gutter-extra-small;
       display: flex;
       justify-content: space-between;
       border-bottom: 1px solid $color-black;
     }
   }
 
   &__footer-bottom {
     margin-top: $gutter-extra-small;
     display: flex;
     flex-direction: row;
     border-top: 1px solid $color-black;
 
     div {
       min-height: 10rem;
       padding: $gutter-extra-small;
       display: flex;
       flex-direction: column;
       flex: 1;
       justify-content: space-between;
       align-items: center;
       font-size: $font-size-small;
 
       p {
         &:nth-child(2) {
           font-weight: bold;
         }
       }
 
       &:not(:last-child) {
         border-right: 1px solid $color-black;
       }
     }
   }
 }
 