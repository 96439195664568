.table {
  border: 3px double;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr.error {
    td {
      background-color: #FFBABA;
    }
  }
  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      width: 150px;
      min-width: 150px;
      max-width: 150px;
      input, .input__container, .input__preview {
        text-align: right;
      }
      .input__preview {
        margin-bottom: 0;
        p {
          display: block;
          width: 100%;
        }
      }
      &.red {
        color: red;
      }
    }
    &.persentase, &.nominal.persentase {
      text-align: right;
      min-width: 80px;
    }
    &.file {
      & a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  tr.total {
    td { color:#000000; }
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
        font-weight: bold;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .border_double {
    border-right: 2px #000000 solid;
    border-left: 2px #000000 solid;
    &.border_double__left {
      border-right: 1px solid;
    }

    &.border_double__right {
      border-left: 1px solid;
    }
  }

  .sub_header {
    border-top: 3px solid;
    border-bottom: 3px solid;
  }

  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-filters {
  display: flex;
  & > * {
    margin-right: 1rem;
  }
  & > *:last-child {
    margin-right: 0;
  }
}

.table-daftarnilai {
  border-collapse: collapse;
  width: 200%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}


.table-raport {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }
  
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-signature {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}


.table-report-header {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: none;
    padding: 0;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 0;
    padding-bottom: 0;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-anekdot {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    //border: 1px solid $color-black;
    padding: 8px;
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.table-indikator {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tr.total {
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  td.test {
    margin-left: 50px;
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-invoice {
  // border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;
  border: 1px solid $color-black;
  border-radius: 20px;

  td, th {
    padding: 8px;
  }
  
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tbody tr td{
    border-bottom: 1px solid lightgrey;
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: none;
    color: black;
    border-bottom: 1px solid $color-black;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-report-cetak {
  border-collapse: collapse;
  width: 80%;
  margin-bottom: $gutter-default;
  margin-left: 30;

  td, th {
    &.big{
      width: 80%;
    }
    border: none;
    padding: -100;
  }
  hr{
    &.bold {
    border-top: 3px solid,
    }
    &.normal{
      border-top: 1px solid;
    }
  }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    &.border{
      padding-top: -1px;
      padding-right: 30px;
      padding-bottom: -1px;
      padding-left: 45px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    &.borderRight{
      padding-top: -1px;
      padding-right: 40px;
      padding-bottom: -1px;
      padding-left: 30px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-middle-cetak {
  border-collapse: collapse;
  width: 85%;
  margin-top: -30px;
  margin-left: 30;

  // td, th {
  //   width: 200;
  //   border: none;
  //   padding: -100;
  // }
  // hr{
  //   &.bold {
  //   border-top: 3px solid,
  //   }
  //   &.normal{
  //     border-top: 1px solid;
  //   }
  // }

  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    &.border{
      padding-top: -1px;
      padding-right: 30px;
      padding-bottom: -1px;
      padding-left: 40px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    &.borderRight{
      padding-top: -1px;
      padding-right: 40px;
      padding-bottom: -1px;
      padding-left: 30px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-middle2-cetak {
  border-collapse: collapse;
  width: 85%;
  margin-top: 20px;
  margin-left: 30;

  td, th {
    border: none;
    padding: -100;
    &.foot{
      margin-top: 50px;

    }
  }
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    &.border{
      padding-top: -1px;
      padding-right: 30px;
      padding-bottom: -1px;
      padding-left: 40px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    &.borderRight{
      padding-top: -1px;
      padding-right: 40px;
      padding-bottom: -1px;
      padding-left: 30px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-foot-cetak {
  border-collapse: collapse;
  width: 85%;
  margin-top: 55px;
  margin-left: 30;

  td, th {
    border: none;
    padding: -100;
    &.foot{
      margin-top: 50px;
      border: 1px solid;

    }
  }
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    &.border{
      padding-top: -1px;
      padding-right: 30px;
      padding-bottom: -1px;
      padding-left: 40px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    &.borderRight{
      padding-top: -1px;
      padding-right: 40px;
      padding-bottom: -1px;
      padding-left: 30px;
      border: 1px solid;
      text-align: center;
      width:25%;
      font-size: x-small;
    }

    
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.table-modal {
  // border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;
  border: 1px solid grey;
  // border-radius: 20px;

  td, th {
    padding: 8px;
  }
  
  td {
    font-size: $font-size-default;
    &.nominal {
      text-align: right;
      input, .input__container, .input__preview {
        text-align: right;
      }
      &.red {
        color: red;
      }
    }
  }

  tbody tr td{
    border-bottom: 1px solid lightgrey;
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background: none;
    color: black;
    border-bottom: 1px solid $color-black;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}

.tablespp {
  border: 3px double;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: $gutter-default;

  td, th {
    border: 1px solid $color-black;
    padding: 8px;
    font-size: 11px;
  }

  tr td, tr th {
    &:nth-child(1) {
      width: 2%;
    }
  }

  tr.error {
    td {
      background-color: #FFBABA;
    }
  }
  tr:nth-child(even){
    //background-color: #f2f2f2;
  }

  tr:nth-child(odd){
    //background-color: $color-white
  }

  tr:hover {
    //background-color: #ddd;
  }

  td {
    // font-size: $font-size-default;
    &.nominal {
      text-align: right;
      width: 150px;
      min-width: 150px;
      max-width: 150px;
      input, .input__container, .input__preview {
        text-align: right;
      }
      .input__preview {
        margin-bottom: 0;
        p {
          display: block;
          width: 100%;
        }
      }
      &.red {
        color: red;
      }
    }
    &.reportspp {
      text-align: right;
      width: 80px;
      min-width: 80px;
      max-width: 80px;
      input, .input__container, .input__preview {
        text-align: right;
      }
      .input__preview {
        margin-bottom: 0;
        p {
          display: block;
          width: 100%;
        }
      }
      &.red {
        color: red;
      }
    }
    &.persentase, &.nominal.persentase {
      text-align: right;
      min-width: 80px;
    }
    &.file {
      & a {
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  tr.total {
    td { color:#000000; }
    &.summary {
      td, td.col_rekomendasi{
        background-color: #AFEEEE;
        font-weight: bold;
      }
    }
    td {
      background-color: #DEDEDE;
    }
  }

  tfoot {
    tr.total {
      td {
        background-color: #AFEEEE;
      }
    }
  }

  th {
    // font-size: $font-size-default;
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: center;
  }

  &__actions {
    display: flex;

    button {
      margin-right: $gutter-extra-small;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  .border_double {
    border-right: 2px #000000 solid;
    border-left: 2px #000000 solid;
    &.border_double__left {
      border-right: 1px solid;
    }

    &.border_double__right {
      border-left: 1px solid;
    }
  }

  .sub_header {
    border-top: 3px solid;
    border-bottom: 3px solid;
  }

  &_title {
    h1, h2, h3{
      text-align: center;
    }
  }
}